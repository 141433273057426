import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { GlobalContext } from './context';

// Pages

import Homepage from '../views/pages/Homepage/index'
import Maintenance from '../views/pages/Maintenance/index';
import Details from '../views/pages/Details/index';
import SelectAppointment from '../views/pages/SelectAppointment/index';
import ConfirmAppointment from '../views/pages/ConfirmAppointment/index';
import AppointmentBooked from '../views/pages/AppointmentBooked';
import EditDetails from '../views/pages/EditDetails';

const Private = ({ job, ...props }) => job.voucher !== null ? <Route {...props} /> : <Redirect to="/" />;
const Guest = ({ job, ...props }) => job.voucher === null ? <Route {...props} /> : <Redirect to="/details" />;

const ExportingComponent = (props) => {
  const { job, configuration } = GlobalContext();

  return (<Router>
    <Switch>
      {
        configuration.maintenance_toggled === true ?
          <Guest job={job} exact path="/" component={Maintenance} /> :
          <Guest job={job} exact path="/" component={Homepage} />
      }
      <Private job={job} exact path="/details" component={Details} />
      <Private job={job} exact path="/edit-details" component={EditDetails} />
      <Private job={job} exact path="/select-appointment" component={SelectAppointment} />
      <Private job={job} exact path="/confirm-appointment" component={ConfirmAppointment} />
      <Private job={job} exact path="/appointment-booked" component={AppointmentBooked} />
    </Switch>
  </Router>)
}

export default ExportingComponent;