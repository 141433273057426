import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/Container';
import { Button, Grid, Input, Select, MenuItem, Icon, InputAdornment } from '@mui/material';
import { GlobalContext } from '../../../utils/context';
import { createAmplitudeEvent, logError, searchForPostcode, useWindowSize, isJobBooked } from '../../../utils/helpers';
import { HTTPRequest } from '../../../utils/endpoints';
import moment from 'moment';

const ExportingComponent = (props) => {
  const { setJob, job, setAlert } = GlobalContext();
  const [width] = useWindowSize();
  const [submitted, setSubmitted] = useState(false);
  const [manuallyEnter, setManuallyEnter] = useState(false);
  const [searchResults, setResults] = useState([]);

  const [values, setValues] = useState({})

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const data = {
      mobileNumber: job.customerDetail.mobileNo,
      line1: job.installationAddress.fittingAddressLine1,
      line2: job.installationAddress.fittingAddressLine2,
      town: job.installationAddress.fittingTown,
      county: job.installationAddress.fittingCounty,
      postcode: job.installationAddress.fittingPostalCode
    };

    setValues(data);
    setInitialValues(data);

    // eslint-disable-next-line
  }, [])

  const cancelPressed = async () => {
    await createAmplitudeEvent(`Pressed "Back"`);
    props.history.goBack();
  }

  const validPhoneNumber = (val) => {
    const phoneRegex = /^(?:0|\+44)7[0-9]{9}$/;
    return phoneRegex.test(val);
  }

  const jobIsBookedAndAddressChanged = () => {
    const { mobileNumber: oldNumber, ...oldAddress } = initialValues;
    const { mobileNumber: newNumber, ...newAddress } = values;
    if (isJobBooked(job) && (JSON.stringify(oldAddress) !== JSON.stringify(newAddress))) {
      return true;
    } else return false;
  }

  const reloadJobUuid = async () => {
    let jobData = null;

    for (let attempts = 1; attempts <= 3; ++attempts) {
      const data = await HTTPRequest(
        "POST",
        {
          vrn: job.vehicleDetail.carRegNumber,
          postcode: values.postcode
        },
        "getJobDetailsByRegAndPostcode"
      );

      jobData = data.jobs[0];

      if (jobData) {
        break;
      }

      console.log(`Failed to load modified job ${attempts} time(s)`);

      // Wait one second before trying again
      await new Promise((r) => setTimeout(r, 1000));
    }

    if (!jobData) {
      props.setAlert({
        active: true,
        title: `Sorry!`,
        contact: true,
        message: `Something has gone wrong. Please reload the page and try to log in again.`
      })
      return false;
    }

    const newJobData = {
      ...job,
      uuid: jobData.uuid,
      installationAddress: jobData.installationAddress
    }
    localStorage.setItem("@currentJob", JSON.stringify(newJobData));
    setJob(newJobData);
  }

  const saveChanges = async (skipCheck = false) => {
    const postcode_check = new RegExp(/([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})/).test(values.postcode);
    if (!postcode_check || values.postcode.trim().length < 1) {
      setAlert({
        title: `Error`,
        message: `Please check the details and try again. The postcode seems to be invalid.`,
        contact: false,
        active: true
      });
      return false;
    } else if (values.line1.trim().length < 1) {
      setAlert({
        title: `Error`,
        message: `Please select or manually complete the address details above before continuing.`,
        contact: false,
        active: true
      });
      return false;
    } else if (!validPhoneNumber(values.mobileNumber)) {
      setAlert({
        title: `Error`,
        message: `Please check the details and try again. The mobile number seems to be ${values.mobileNumber.length < 1 ? `empty` : `invalid`}. Please make sure the phone number starts with 07 and is made of 11 digits, or starts with +447 and is made of 13 digits.`,
        contact: false,
        active: true
      });
      return false;
    }
    if (jobIsBookedAndAddressChanged() === true && skipCheck !== true) {
      setAlert({
        title: `Warning`,
        active: true,
        message: <React.Fragment>If you change your installation address your booking for <b>{moment(job.bookingDetails[0].bookingDate).format("dddd, DD MMM YYYY")}</b> will be cancelled and will need to be rebooked. Are you sure you want to change your address?</React.Fragment>,
        okButtonLabel: `No`,
        buttonLabel: `Yes`,
        buttonOnClick: () => {
          saveChanges(true);
        }
      })
      return false;
    }
    try {
      setSubmitted(true);
      window.setAppLoading(true);
      await createAmplitudeEvent(`Pressed "Save Changes"`);
      // If the address is changed and job is booked we must cancel appt.

      let apptCancelledProps = {};

      if (jobIsBookedAndAddressChanged() === true) {
        try {
          await HTTPRequest(
            "POST",
            {
              "jobUuid": job.uuid,
              "jobCancelled": false
            },
            "cancelAppointment"
          );
        } catch (err) {
          if (err.response && err.response.status === 409) {
            await createAmplitudeEvent(`Cannot cancel`);
            setAlert({
              active: true,
              title: `Sorry!`,
              message: <React.Fragment>
                We cannot make changes to your appointment online at this time. Please contact the RS Connect office on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>
              </React.Fragment>
            })
            window.setAppLoading(false);
            setSubmitted(false);
            return;
          } else {
            throw err;
          }
        }

        apptCancelledProps = {
          appointmentSlotUrn: null,
          appointmentDate: null,
          bookingDetails: [],
          jobStatus: "Book Appointment"
        };
      }

      await HTTPRequest(
        "POST",
        {
          uuid: job.uuid,
          mobileNumber: values.mobileNumber,
          address1: values.line1,
          address2: values.line2,
          town: values.town,
          county: values.county,
          postcode: values.postcode
        },
        "changeAddress"
      );

      setJob({
        ...job,
        installationAddress: {
          fittingAddressLine1: values.line1,
          fittingAddressLine2: values.line2,
          fittingCounty: values.county,
          fittingPostalCode: values.postcode,
          fittingTown: values.town
        },
        customerDetail: {
          ...job.customerDetail,
          mobileNo: values.mobileNumber
        },
        ...apptCancelledProps
      });

      if (values.postcode !== initialValues.postcode) {
        await reloadJobUuid();
      }

      await createAmplitudeEvent(`Updated Installation Address`, {
        newAddress: values,
        appointment_cancelled: Object.keys(apptCancelledProps).length > 0 ? true : false
      });

      if (Object.keys(apptCancelledProps).length > 0) {
        setAlert({
          title: "Success",
          message: `The installation address has been updated successfully. Please go ahead and reschedule the date for your installation.`,
          contact: false,
          active: true
        });
      } else {
        setAlert({
          title: "Success",
          message: `Your details have been updated successfully`,
          contact: false,
          active: true
        });
      }
      props.history.replace("/details");
      window.setAppLoading(false);
    } catch (err) {
      await createAmplitudeEvent("Having Difficulties", {
        reason: "Failed to update installation address"
      })
      await logError("SAVE_ADDRESS", err);
      setAlert({
        active: true,
        contact: true,
        title: "Oh No!",
        message: `We've encountered a server error while trying to process your request. Please check your information and try again.`
      })
      window.setAppLoading(false);
      setSubmitted(false);
    }
  }

  const postcodeChanged = (newPostcode) => {
    setValues({
      ...values,
      line1: '',
      line2: '',
      town: '',
      county: '',
      postcode: newPostcode
    });
  }

  const searchPostcode = async () => {
    try {
      await createAmplitudeEvent(`Searching for postcode`, { postcode: values.postcode });
      setValues({
        ...values,
        line1: '',
        line2: '',
        town: '',
        county: ''
      })
      setSubmitted(true);
      const results = await searchForPostcode(values.postcode);
      setSubmitted(false);
      setResults(results);
      if (results.length < 1) {
        setAlert({
          title: "Oops",
          message: `We failed to find the address you're looking for. Please check the postcode and try again or contact us for help.`,
          active: true,
          contact: true
        })
      }
    } catch (err) {
      setSubmitted(false);
      setResults([]);
      logError("FIND_POSTCODE", err);
      await createAmplitudeEvent(`Having Difficulties`, { reason: "Failed to find a postcode due to server error" });
      setAlert({
        active: true,
        contact: true,
        title: "Oh No!",
        message: `We've encountered a server error while trying to process your request. Please check your information and try again.`
      })
    }
  }

  const onAddressPicked = async (e) => {
    const index = e.target.value;
    const add = searchResults[index];

    const { line1, line2, town, postcode, county } = add;
    setValues({
      ...values,
      line1, line2, town, postcode, county
    });
    await createAmplitudeEvent(`Select address from results`, {
      address_selected: {
        line1, line2, town, postcode, county
      }
    });
    setResults([]);
    setManuallyEnter(true);
  }

  return <PageContainer pageTitle="Details" className="page-edit-details">
    <div className="page-header">
      <div className="left-side">
        <div className="title">Edit details</div>
        <div className="description">Update your contact number and/or installation address</div>
      </div>
    </div>
    <div className="page-content">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <div className="form-element">
            <div className="label">Mobile Number</div>
            <Input
              className="input-element"
              value={values.mobileNumber}
              onChange={(e) => setValues({ ...values, mobileNumber: e.target.value })}
              placeholder="Mobile Number"
              fullWidth={true}
              disabled={submitted}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className="form-element">
            <div className="label">Postcode</div>
            <Input
              className="input-element"
              value={values.postcode}
              disabled={submitted}
              onChange={(e) => postcodeChanged(e.target.value.toString().toUpperCase())}
              placeholder="Postcode"
              fullWidth={true}
              endAdornment={manuallyEnter === false ? <InputAdornment position="end">
                <div className={submitted ? `search-icon-container disabled` : `search-icon-container`} onClick={searchPostcode}>
                  <Icon>search</Icon>
                </div>
              </InputAdornment> : undefined}
            />
          </div>
          {
            manuallyEnter === false && searchResults.length > 0 && <React.Fragment>
              <div className="form-element">
                <div className="label">Select Address</div>
                <Select value="none" className="input-element" onChange={async (e) => await onAddressPicked(e)}>
                  <MenuItem disabled value="none">Click here to pick an address</MenuItem>
                  {
                    searchResults.map((o, index) => <MenuItem key={index} value={index}>
                      {
                        [o.line1, o.line2, o.town, o.county].filter((str) => str.length > 0).join(", ")
                      }
                    </MenuItem>)
                  }
                </Select>
              </div>
            </React.Fragment>
          }
          {
            manuallyEnter === true && <React.Fragment>
              <div className="form-element">
                <div className="label">Address Line 1</div>
                <Input
                  className="input-element"
                  value={values.line1}
                  disabled={submitted}
                  onChange={(e) => setValues({ ...values, line1: e.target.value })}
                  placeholder="Address line 1"
                  fullWidth={true}
                />
              </div>
              <div className="form-element">
                <div className="label">Address Line 2</div>
                <Input
                  className="input-element"
                  value={values.line2}
                  disabled={submitted}
                  onChange={(e) => setValues({ ...values, line2: e.target.value })}
                  placeholder="Address line 2"
                  fullWidth={true}
                />
              </div>
              <div className="form-element">
                <div className="label">Town/City</div>
                <Input
                  className="input-element"
                  value={values.town}
                  disabled={submitted}
                  onChange={(e) => setValues({ ...values, town: e.target.value })}
                  placeholder="Town/City"
                  fullWidth={true}
                />
              </div>
              <div className="form-element">
                <div className="label">County</div>
                <Input
                  className="input-element"
                  value={values.county}
                  disabled={submitted}
                  onChange={(e) => setValues({ ...values, county: e.target.value })}
                  placeholder="County"
                  fullWidth={true}
                />
              </div>
            </React.Fragment>
          }
          <div className="form-element">
            <span className={submitted ? `switch-button disabled` : `switch-button`} onClick={() => setManuallyEnter(!manuallyEnter)}>{manuallyEnter ? `Search Address` : `Enter Address Manually`}</span>
          </div>
        </Grid>
      </Grid>
    </div>
    <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
    <div className="footer-page">
      <Button
        variant="contained"
        color="primary"
        onClick={saveChanges}
        disabled={
          submitted ||
          (JSON.stringify(initialValues) === JSON.stringify(values))
        }
        fullWidth={width < 1000}>Save Changes</Button>
      <Button variant="outlined" color="primary" onClick={cancelPressed} disabled={submitted} fullWidth={width < 1000}>Back</Button>
    </div>
  </PageContainer>
}

export default ExportingComponent;
