import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/material';
import { GlobalContext } from '../../utils/context';

import HeaderComponent from './Header';
import FooterComponent from './Footer';

const ExportingComponent = (props) => {
  const { configuration } = GlobalContext();

  useEffect(() => {
    if(configuration && configuration.theme_name) {
      document.body.className = `theme_${configuration.theme_name}`
    }
    // eslint-disable-next-line
  }, [configuration.theme_name]);

  return <React.Fragment>
    <Helmet>
      <title>{configuration.seo_name.replace("{{Page}}", props.pageTitle)}</title>
      <link rel="icon" href={`https://${configuration.seo_favicon}`} />
      {
        configuration.theme_name !== "default" &&
        <link rel="stylesheet" href={`assets/css/${configuration.theme_name}.css`} />
      }
    </Helmet>
    <HeaderComponent />
    <Container maxWidth="xl" className={`layout-container ${props.className || ''}`}>
      {props.children}
    </Container>
    <FooterComponent />
  </React.Fragment>
}

export default ExportingComponent;