import { ThemeProvider, createTheme, CssBaseline } from '@mui/material'
import { CircularProgress } from "@material-ui/core"
import React, {useEffect, useState} from 'react';
import { SnackbarProvider } from 'notistack';

const Theme = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.setAppLoading = (value) => setLoading(value);
  }, [])

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Roboto',
        'sans-serif'
      ].join(','),
    },
    palette: {
      mode: `light`,
      primary: {
        main: '#5B9EF4'
      },
      secondary: {
        main: '#6A767B'
      }
    }
  });

  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider maxSnack={3}>
      {props.children}
      {
        loading === true && <div className="app-loading-container">
          <CircularProgress />
        </div>
      }
    </SnackbarProvider>
  </ThemeProvider>
}

export default Theme;