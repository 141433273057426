import React, { useState } from 'react';
import { Input, Button } from '@mui/material';
import { createAmplitudeEvent, isStatusEditable, logError, useWindowSize } from '../../../../utils/helpers';
import { HTTPRequest } from '../../../../utils/endpoints';
import { GlobalContext } from '../../../../utils/context';

const ExportingComponent = (props) => {
  const [postcode, setPostcode] = useState('');
  const [registration, setRegistration] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { configuration, setJob, setConfiguration } = GlobalContext();
  const [ width ] = useWindowSize();

  const formatPostcode = (postcode) => {
    let res = `${postcode}`;
    let manipulatedArr = res.replace(/ /g, "").split("");
    const combinedPostcode = manipulatedArr;
    let index = 2;
    if(combinedPostcode.length === 7) {
      index = 4;
    } else if(combinedPostcode.length === 6) {
      index = 3;
    }
    
    manipulatedArr.splice(index, 0, `%`);
    res = manipulatedArr.join("");
    return res;
  }

  const formatRegistration = (reg) => reg.replace(/ /g, "");

  const submit = async () => {
    try {
      window.setAppLoading(true);
      setSubmitted(true);
      const parsedPostcode = formatPostcode(postcode);
      const parsedRegistration = formatRegistration(registration);

      const data = await HTTPRequest(
        "POST",
        {
          vrn: parsedRegistration,
          postcode: parsedPostcode
        },
        "getJobDetailsByRegAndPostcode"
      );

      const jobData = data.jobs[0];
      if(!jobData) {
        props.setAlert({
          active: true,
          title: `Sorry!`,
          contact: true,
          message: `We couldn’t find your details with the information provided. Please check your information and try again.`
        })
        setSubmitted(false);
        window.setAppLoading(false);
        return false;
      }
      setSubmitted(false);
      const newJobData = {
        uuid: jobData.uuid,
        voucher: jobData.voucher,
        ins: jobData.ins,
        contract_name: jobData.contracts_product[0].contract.name,
        contract_type: jobData.contracts_product[0].contract.type,
        configuration_name: configuration.name,
        customerDetail: jobData.customerDetail,
        vehicleDetail: jobData.vehicleDetail,
        isDeployed: jobData.isDeployed,
        installationAddress: jobData.installationAddress,
        bookingDetails: jobData.bookingDetails,
        appointmentSlotUrn: null,
        appointmentDate: null,
        jobStatus: jobData.statusDetail.status,
        jobEditable: isStatusEditable(jobData.statusDetail.status)
      }
      localStorage.setItem("@currentJob", JSON.stringify(newJobData));
      setJob(newJobData);
      window.insurerQueryForced = jobData.contracts_product[0].contract.name
      setConfiguration((currentState) => {
        return {...currentState, reload_configuration: true }
      });
      await createAmplitudeEvent(`Self-service - Home`);
      await createAmplitudeEvent(`Confirm Details`);
      window.setAppLoading(false);

      if(jobData.statusDetail.status !== 'Book Appointment' && jobData.statusDetail.status !== 'Confirmed Appointment'){
        props.setAlert({
          active: true,
          title: `Sorry!`,
          contact: false,
          message: `We are unable to book this appointment via this service.  Please contact a member of our bookings team on 01675 624 020.`
        })
      }

    } catch(err) {
      setSubmitted(false);
      await logError("GET_JOB_BY_REG_AND_POSTCODE", err);
      props.setAlert({
        active: true,
        title: `Oh No!`,
        message: `We've encountered a server error while trying to process your request. Please check your information and try again.`
      })
      window.setAppLoading(false);
    }
  }

  return <React.Fragment>
    <div className="form-element">
      <div className="label">Vehicle Registration Number</div>
      <Input
        placeholder="Vehicle Registration Number"
        className="input-element"
        value={registration}
        disabled={submitted}
        onChange={ (e) => setRegistration(e.target.value) }
      />
      <div className="instruction">* Must be the VRN sent to RS Connect.</div>
    </div>
    <div className="form-element">
      <div className="label">Postcode</div>
      <Input
        placeholder="Postcode"
        className="input-element"
        value={postcode}
        disabled={submitted}
        onChange={ (e) => setPostcode(e.target.value) }
      />
      <div className="instruction">* If you have already booked your appointment and selected a new address please use the latest postcode when logging in</div>
    </div>
    <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth={width < 1000 ? true : false }
        disabled={submitted || registration.trim().length < 1 || postcode.trim().length < 1 } 
        onClick={submit}>Log in</Button>
    </div>
    {

    }
  </React.Fragment>
}

export default ExportingComponent;
