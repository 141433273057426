import React from 'react';
import { Container } from '@mui/material';

const ExportingComponent = (props) => {
  return <React.Fragment>
    <div className="layout-footer">
      <Container maxWidth="xl">
        <div className="content">
          <div className="links">
            <div className="mobile-heading">Links</div>
            <a target="_blank" rel="noopener noreferrer" href="https://rsconnect.com/content/privacy-policy">Your Privacy</a>
            <a target="_blank" rel="noopener noreferrer" href="https://rsconnect.com">RSConnect.com</a>
          </div>
          <div className="copyright">This service is provided by RS Connect</div>
        </div>
      </Container>
    </div>
  </React.Fragment>
}

export default ExportingComponent;