import Axios from 'axios';

const dev = require(`../endpoint_data/dev.json`)
const live = require(`../endpoint_data/live.json`)
const { REACT_APP_API_ENVIRONMENT } = process.env;

export const HTTPRequest = async (method, data, endpoint = undefined, timeout = 30000) =>
{
  const isDev = (REACT_APP_API_ENVIRONMENT === `dev`);
  const envEndpointData = (isDev) ?  dev : live;

  const { uri, headers } = envEndpointData;

  const request = { headers, data, method, timeout };
  const url = (endpoint) ? `${uri}/${endpoint}` : `${uri}`;

  const { data: responseData } = await Axios(url, request);

  return responseData;
}