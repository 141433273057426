import React from 'react';
import ReactDOM from 'react-dom';
import Wrapper from './utils/wrapper';
import Router from './utils/router';
import Context from './utils/context'
import Theme from './utils/theme';

ReactDOM.render(<Context>
  <Theme>
    <Wrapper>
      <Router />
    </Wrapper>
  </Theme>
</Context>, document.getElementById('root'));