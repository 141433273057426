import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/Container';
import { Button } from '@mui/material';
import { GlobalContext } from '../../../utils/context';
import { useWindowSize, createAmplitudeEvent, logError } from '../../../utils/helpers';
import { HTTPRequest } from '../../../utils/endpoints';
import { Select, MenuItem, Checkbox } from '@mui/material';
import moment from 'moment';

const ExportingComponent = (props) => {
  const { job, setJob, setAlert } = GlobalContext();
  const [width] = useWindowSize();
  const [appointments, setAppointments] = useState([]);
  const [apptIndex, setApptIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [datesNotWorking, setDatesNotWorking] = useState(false);
  const [batchNo, setBatchNo] = useState(0);

  const maxBatches = 4;

  useEffect(() => {
    loadAppointmentDates(batchNo);
    // eslint-disable-next-line
  }, [])

  const loadAppointmentDates = async () => {
    try {
      window.setAppLoading(true);
      setLoading(true);
      let newAppointments = null;
      let locBatchNo = batchNo;

      while (
        (
          (newAppointments == null)
          || (newAppointments.length === 0)
        )
        && (locBatchNo < maxBatches)
      ) {
        try {
          newAppointments = await HTTPRequest(
            "POST",
            {
              "jobUuid": job.uuid,
              "sla": "internal",
              "batchNo": locBatchNo
            },
            "getAvailableAppointments"
          );

          ++locBatchNo;
        } catch (err) {
          if (err.response && err.response.status === 409) {
            await createAmplitudeEvent(`Cannot rebook`);
            setAppointments([]);
            setLoading(false);
            setAlert({
              active: true,
              title: `Sorry!`,
              message: <React.Fragment>
                We cannot make changes to your appointment online at this time. Please contact the RS Connect office on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>
              </React.Fragment>
            })
            window.setAppLoading(false);
            return;
          } else {
            throw err;
          }
        }
      }

      await createAmplitudeEvent(`Installation dates`, {
        dates: newAppointments.map((ap) => {
          return {
            date: ap.date,
            engineers: ap.engineers.length
          }
        })
      });
      const allAppointments = appointments.concat(newAppointments);
      setAppointments(allAppointments);
      setLoading(false);
      if (allAppointments.length < 1) {
        await createAmplitudeEvent(`No Available Dates`, {
          postcode: job.installationAddress.fittingPostalCode
        });
        setAlert({
          active: true,
          title: `Sorry!`,
          message: <React.Fragment>
            There are currently no available engineers in your area. Please amend the installation address or contact us on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>
          </React.Fragment>
        })
      }
      window.setAppLoading(false);
      setBatchNo(locBatchNo);
    } catch (err) {
      await logError("GET_APPOINTMENTS", err, { uuid: job.uuid });
      await createAmplitudeEvent(`Having Difficulties`, {
        reason: "Failed to get list of available appointments"
      })
      setAppointments([]);
      setLoading(false);
      setAlert({
        active: true,
        title: `Experiencing Difficulties`,
        message: `We've encountered a server error while trying to process your request. Please check your information and try again.`
      })
      window.setAppLoading(false);
    }
  }

  const cancelFlow = async () => {
    await createAmplitudeEvent(`Selected "Cancel"`);
    setJob({
      ...job,
      appointmentSlotUrn: null,
      appointmentDate: null
    });
    props.history.goBack();
  }

  const bookAppointment = async () => {
    await createAmplitudeEvent(`Selected "Book Appointment"`);
    props.history.push(`/confirm-appointment`);
  }

  const selectedSlot = async (index) => {
    const appt = appointments[index];
    setJob({
      ...job,
      appointmentDate: appt.date,
      appointmentSlotUrn: appt.engineers[0].slotUrn
    });
    setApptIndex(index);
  }

  return <PageContainer pageTitle="Installation Dates" className="page-select-appts">
    <div className="page-header">
      <div className="left-side">
        <div className="title">Installation Dates</div>
        <div className="description">Please select a date and ensure the vehicle is available between 8AM - 5PM. We will confirm a timeslot closer to your appointment date.</div>
      </div>
    </div>
    <div className="page-content">
      <div className="label">Select Date</div>
      <Select
        style={{ width: '350px', maxWidth: '100vw' }}
        disabled={loading || (loading === false && appointments.length < 1)}
        onChange={(e) => selectedSlot(e.target.value)}
        value={apptIndex === null ? "none" : apptIndex}>
        <MenuItem value="none" disabled>{loading ? `Loading...` : (appointments.length > 0 ? `Click to select an appointment date` : `No available appointments`)}</MenuItem>
        {
          appointments.map((ap, index) => <MenuItem key={index} value={index}>
            {
              moment(ap.date).format("dddd, DD MMM YYYY")
            }{ap.engineers.length < 3 && ` - Limited`}
          </MenuItem>)
        }
      </Select>
      <Button
        variant="contained" color="primary"
        onClick={async () => { setApptIndex(null); await loadAppointmentDates(); }}
        disabled={(batchNo > (maxBatches - 1)) || loading}
        fullWidth={width < 1000}
        style={{ width: '350px', maxWidth: '100vw', marginTop: '8px' }}>Get Next 7 Days</Button>
      <div className="checkbox">
        <Checkbox checked={datesNotWorking} onClick={() => setDatesNotWorking(!datesNotWorking)} />
        <label>None of these dates work for me</label>
      </div>
      {
        datesNotWorking === true && <div className="checkbox-text">
          Please contact our bookings team who may be able to assist further on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>
        </div>
      }
    </div>
    <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
    <div className="footer-page">
      <div className="buttons">
        <Button
          variant="contained" color="primary"
          onClick={bookAppointment}
          disabled={loading || job.appointmentDate === null || apptIndex === null}
          fullWidth={width < 1000}
          style={{ marginBottom: width < 1000 ? 8 : 0 }}>Book Appointment</Button>
        <Button variant="outlined" color="primary" onClick={cancelFlow} disabled={loading} fullWidth={width < 1000} style={{ marginLeft: width > 1000 ? 8 : 0 }}>Edit Details</Button>
      </div>
    </div>
  </PageContainer>
}

export default ExportingComponent;
