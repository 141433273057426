import React, { useState } from 'react';
import PageContainer from '../../components/Container';
import { Grid, Button } from '@mui/material';
import { GlobalContext } from '../../../utils/context';
import { useWindowSize, createAmplitudeEvent, logError, isJobBooked } from '../../../utils/helpers';
import moment from 'moment';
import { HTTPRequest } from '../../../utils/endpoints';

const ExportingComponent = (props) => {
  const { job, setJob, setAlert } = GlobalContext();
  const [width] = useWindowSize();
  const [loading, setLoading] = useState(false);

  const notMyDetails = async () => {
    await createAmplitudeEvent(`Selected "Not My Details"`);
    setAlert({
      active: true,
      contact: false,
      title: `Not you?`,
      message: <React.Fragment>
        Sorry about that - please contact us on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a> to talk to us and we will be happy to look into this for you
      </React.Fragment>
    })
  }

  const cancelFlow = async () => {
    await createAmplitudeEvent(`Selected "Cancel"`);
    props.history.replace("/details");
  }

  const confirmAppointment = async () => {
    try {
      window.setAppLoading(true);
      await createAmplitudeEvent(`Selected "Confirm Appointment"`);
      await createAmplitudeEvent("End of booking");
      setLoading(true);

      const endpoint = isJobBooked(job) ? "rescheduleAppointment" : "bookAppointment";
      await HTTPRequest(
        "POST",
        {
          jobUuid: job.uuid,
          slotUrn: job.appointmentSlotUrn,
          bookingDate: job.appointmentDate,
          preferences: {
            callOnWay: false
          }
        },
        endpoint
      );

      setJob({
        ...job,
        jobStatus: "Confirmed Appointment",
        bookingDetails: [
          {
            bookingDate: job.appointmentDate
          }
        ]
      });
      window.setAppLoading(false);
      props.history.push(`/appointment-booked`);
    } catch (err) {
      setAlert({
        active: true,
        title: `Oh no!`,
        message: `We've encountered a server error while trying to process your request. Please check your information and try again.`
      })
      setLoading(false);
      await createAmplitudeEvent("Having Difficulties", {
        reason: "Failed to book in the appointment"
      })
      await logError("BOOK_APPOINTMENT", err);
      window.setAppLoading(false);
    }
  }

  return <PageContainer pageTitle="Confirm Appointment" className="page-details">
    <div className="page-header">
      <div className="left-side">
        <div className="title">Confirm Appointment</div>
        <div className="description">Please check the details below are correct before confirming your appointment.</div>
      </div>
    </div>
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <div className="info-title">Personal</div>
        <div className="info-data">{job.customerDetail.customerName}</div>
        <div className="info-data">{job.customerDetail.mobileNo}</div>
      </Grid>
      <Grid item xs={12} lg={8}>
        <div className="info-title">Vehicle</div>
        <div className="info-data">{job.vehicleDetail.carRegNumber}</div>
        <div className="info-data">{job.vehicleDetail.carMake}</div>
        <div className="info-data">{job.vehicleDetail.carModel}</div>
      </Grid>
      <Grid item xs={12} lg={3}>
        <div className="info-title">Installation Address</div>
        {
          [
            job.installationAddress.fittingAddressLine1,
            job.installationAddress.fittingAddressLine2,
            job.installationAddress.fittingTown,
            job.installationAddress.fittingCounty,
            job.installationAddress.fittingPostalCode
          ].filter((d) => d && d.length > 0).map((data, index) => <div className="info-data" key={index}>{data}</div>)
        }
      </Grid>
      <Grid item xs={12} lg={8}>
        <div className="info-title">Installation Date</div>
        <div className="info-data">{moment(job.appointmentDate).format("dddd, DD MMM YYYY")}</div>
      </Grid>
    </Grid>
    <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
    <div className="footer-page">
      <div className="not-my-details" onClick={notMyDetails}>
        Not your details? Click <div className="primary-color" style={{ marginLeft: 4, marginRight: 4 }}>here</div> to let us know.
      </div>
      <div className="buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={confirmAppointment}
          fullWidth={width < 1000}
          disabled={loading}
          style={{ marginBottom: width < 1000 ? 8 : 0 }}>Confirm Appointment</Button>
      </div>
      <Button
        variant="outlined"
        color="primary"
        disabled={loading}
        onClick={cancelFlow}
        fullWidth={width < 1000}
        style={{ marginLeft: width > 1000 ? 8 : 0 }}>
        Edit Appointment
      </Button>
    </div>
  </PageContainer>
}

export default ExportingComponent;
