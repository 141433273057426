import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/Container';
import { useHistory } from 'react-router-dom';

// Components
import PostcodeForm from './components/PostcodeForm';
import { GlobalContext } from '../../../utils/context';

const ExportingComponent = (props) => {
  const history = useHistory();
  const [unlockByPostcode, setUnlockByPostcode] = useState(true);
  const { setAlert } = GlobalContext();

  useEffect(() => {
    const loadInsurerData = async () => {
      const params = new URLSearchParams(window.location.search);
      const voucherNumber = params.get('V') || undefined
      if(voucherNumber){history.push('/');}
      setUnlockByPostcode(true);
    }
    loadInsurerData();
    // eslint-disable-next-line
  }, []);

  return <PageContainer pageTitle="Homepage" className="page-homepage">
    <div className="page-header homepage">
      <div className="left-side">
        <div className="title">Book an Appointment</div>
        <div className="description">
          {
            unlockByPostcode ? `Please enter your Vehicle Registration Number and Postcode to book your appointment` : `We have sent a temporary verification code to your ${process.env.REACT_APP_BOOKING_TYPE === "insurance" ? "mobile" : "email"}. Enter the code and your Vehicle Registration Number (VRN) to verify your identity.`
          }
        </div>
      </div>
    </div>
    <PostcodeForm setAlert={setAlert} />
  </PageContainer>
}

export default ExportingComponent;
