import React, { useState, useEffect } from 'react';
import { GlobalContext } from '../utils/context';
import { getContentfulByEntryID } from '../utils/helpers';
import { CircularProgress, Typography } from '@mui/material';

const FailedComponent = () => {
  window.document.title = "Website failed to load";

  return <div style={{ padding: 16, textAlign: 'center', display: 'flex', height: '100vh', width: '100vw', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Typography variant="h6">Oops.</Typography>
    <Typography variant="subtitle2">The website got an error while loading.</Typography>
    <Typography variant="subtitle2">We have been informed and we will fix this soon!</Typography>
  </div>
}

const LoadingComponent = () => {
  window.document.title = "Website is loading..";

  return <div style={{
    display: 'flex',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    opacity: 0.8
  }}>
    <CircularProgress />
  </div>
}

const mapConfigurations = {
  "insurance": {
    "default": {
      "contentful": "58ttcEp3NmLja88787uj72",
      "theme": "default"
    },
    "drive like a girl": {
      "contentful": "3xKWKIN1B79Cf6UdK08HmO",
      "theme": "drive_like_a_girl"
    },
    "tesco bank": {
      "contentful": "1U0BysP3O3xeAVKUrtnbTg",
      "theme": "tesco_bank"
    },
    "insure the box": {
      "contentful": "57zvNhjst5d1bFtcx9kU9z",
      "theme": "insure_the_box"
    },
    // "sm>rt wheels": {
    //   "contentful": "6vGcejLDJUkNYA7LfLmw6h",
    //   "theme": "smart_wheels"
    // },
    // "carrot insurance": {
    //   "contentful": "7nVPng9twEIWYaKmDGJ5oQ",
    //   "theme": "carrot_insurance"
    // }
  },
  "fleet": {
    "default": {
      "contentful": "5noZrqhEFIg7f2xmP1UALe",
      "theme": "default"
    }
  }
}

window.mapConfigurations = mapConfigurations;
window.insurerQueryForced = undefined;

const ExportingComponent = (props) => {
  const { configuration, setConfiguration } = GlobalContext();
  const [executed, setExecuted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  
  useEffect(() => {
    if (executed === false) {
      const loadContent = async () => {
        const environmentVariables = {
          api_environment: process.env.REACT_APP_API_ENVIRONMENT,
          booking_type: process.env.REACT_APP_BOOKING_TYPE,
          amplitude: process.env.REACT_APP_AMPLITUDE_KEY
        }
        setLoading(true);
        try {
          Object.keys(environmentVariables).forEach((elm, index) => {
            let envVar = environmentVariables[elm];
            if(envVar === undefined || envVar === null || envVar.length < 1) {
              // eslint-disable-next-line
              throw `Environment variable "${elm}" is not set up. Currently that variable has value: ${envVar}`
            }
          });
          const params = new URLSearchParams(window.location.search);
          let queryDetected = window.insurerQueryForced !== undefined ? window.insurerQueryForced : (params.get('I') || undefined)
          let insurerQuery = queryDetected ? queryDetected.trim() : queryDetected;
          if(window.insurerQueryForced !== undefined) {
            // console.log(`We found a new query enforced and now we delete it.`, window.insurerQueryForced)
            window.insurerQueryForced = undefined;
          }
          if(insurerQuery !== undefined) {
            insurerQuery = insurerQuery.toString().toLowerCase()
          } 
          let configuration = mapConfigurations[environmentVariables.booking_type]["default"];
          // console.log(`Looking for configuration for "${insurerQuery}"`)
          if(mapConfigurations[environmentVariables.booking_type][insurerQuery] !== undefined) {
            // console.log(`Found configuration for ${insurerQuery}`)
            configuration = mapConfigurations[environmentVariables.booking_type][insurerQuery];
          }
          const data = await getContentfulByEntryID(configuration.contentful);
          const newConfiguration = {
            name: data.fields.name,
            logos: {
              white: data.fields.logo_variant_1 ? data.fields.logo_variant_1.fields.file.url : null,
              black: data.fields.logo_variant_2 ? data.fields.logo_variant_2.fields.file.url : null,
            },
            text_logo: data.fields.text_logo,
            seo_name: data.fields.seo_name,
            seo_description: data.fields.seo_description,
            seo_keywords: data.fields.seo_keywords,
            seo_favicon: data.fields.seo_favicon ? data.fields.seo_favicon.fields.file.url : null,
            maintenance_toggled: data.fields.maintenance_toggled,
            change_address_toggled: data.fields.change_address,
            content: data.fields.texts_blocks ? data.fields.texts_blocks.fields : null,
            navigation: data.fields.navigation ? data.fields.navigation.fields : null,
            footer: data.fields.footer ? data.fields.footer.fields : null,
            homepage_banner: data.fields.homepage_banner ? data.fields.homepage_banner.fields.file.url : null,
            booking_type: environmentVariables.booking_type.toLowerCase().replace(/\s/g, ''),
            theme_name: configuration.theme,
            reload_configuration: false
          }
          setConfiguration(newConfiguration);
        } catch (err) {
          console.error(`Failed to get configuration`, err)
          setFailed(true);
        }
        setLoading(false);
      }
      loadContent();
      setExecuted(true);
    }
  }, [executed, setConfiguration])

  useEffect(() => {
    if(configuration !== null && configuration.reload_configuration === true) {
      setExecuted(false);
    }
  }, [configuration])

  return loading || (configuration === null && failed === false) ? <LoadingComponent /> : (failed ? <FailedComponent /> : props.children);
}

export default ExportingComponent;