import React from 'react';
import PageContainer from '../../components/Container';
import { Button } from '@mui/material';
import { GlobalContext } from '../../../utils/context';
import { useWindowSize } from '../../../utils/helpers';
import moment from 'moment';

const ExportingComponent = (props) => {
  const { job } = GlobalContext();
  const [width] = useWindowSize();

  const rescheduleAppointment = async () => {
    props.history.push(`/details`)
  }

  return <PageContainer pageTitle="Details" className="page-appointment-booked">
    <div className="page-header">
      <div className="left-side">
        <div className="title">YOU’RE BOOKED IN</div>
        <div className="description success">Your appointment has been booked for <b>{moment(job.appointmentDate).format("dddd, DD MMM YYYY")}</b></div>
      </div>
    </div>
    <div className="page-content">
      <p>We will send you a reminder message the day before your appointment and provide an estimated time slot.</p>
      <p>On the day of your appointment your vehicle keys must be left with a responsible adult who can hand them over to the RS Connect engineer.</p>
      <p>If you would like to change your appointment date please select the button below to rearrange for a more suitable date.</p>
      <p>If you want to speak to a member of our bookings team please contact us on <a href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>. Our opening hours are Monday to Friday, 9am-5pm.</p>
      <div className="divider-rs" style={{ marginTop: 8, marginBottom: 8 }} />
      <p>Please click <a className="primary-color" target="_blank" rel="noopener noreferrer" href="https://rsconnect.com/coronavirus-latest/">here</a> to read more about our engineer processes on the day of your appointment.</p>
    </div>
    <div className="divider-rs" style={{ marginTop: 16, marginBottom: 16 }} />
    <div className="footer-page">
      <Button variant="contained" color="primary" onClick={rescheduleAppointment} fullWidth={width < 1000}>Rearrange Appointment</Button>
    </div>
  </PageContainer>
}

export default ExportingComponent;
