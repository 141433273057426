import React, { useEffect, useState } from 'react';
import { AppBar, Container } from '@mui/material';
import { GlobalContext } from '../../utils/context';
import InfoIcon from '@mui/icons-material/Info';
import { createAmplitudeEvent } from '../../utils/helpers';
import { Modal, Button } from '@mui/material';

const ExportingComponent = (props) => {
  const { job, configuration } = GlobalContext();
  const defaultLogo = `/assets/images/logo.png`;
  const [helpRequired, setHelpRequired] = useState(false);

  const requestHelp = async (external = undefined) => {
    if(external === undefined && job.voucher !== null) {
      console.log("created");
      await createAmplitudeEvent(`Pressed "Information" in Header`);
    }
    setHelpRequired(true);
  }
  
  const requestHelpExternal = async () => await requestHelp(true);

  useEffect(() => {
    window.addEventListener("onRequestHelp", requestHelpExternal);
    return () => {
      window.removeEventListener("onRequestHelp", requestHelpExternal);
    }    
    // eslint-disable-next-line
  }, []);

  const callPhone = () => {
    const PhoneNumber = process.env.REACT_APP_CC_PHONE_NUMBER;
    window.location.href = 'tel://' + PhoneNumber;
  }
  
  return <React.Fragment>
    <AppBar className="layout-header">
      <Container maxWidth="xl">
        <div className="content">
          <div className="logo"
            style={{
              backgroundImage: `url("${configuration.logos.white ? configuration.logos.white : defaultLogo}")`
            }}
          />
          <div className="help-button" onClick={async () => await requestHelp() }>
            <div className="icon">
              <InfoIcon />
            </div>
            <div className="label">Information</div>
          </div>
        </div>
      </Container>
    </AppBar>
    <Modal open={helpRequired} onClose={ () => setHelpRequired(false)}>
      <div className="alert-dialog-container">
        <div className="alert-content wider animate__animated animate__bounceIn">
          <div className="title">Contact us</div>
          <div className="message">
            If you want to speak to a member of our bookings team you can call us on <a className="primary-color" href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a>
          </div>

          <div className="message" style={{ marginTop: 16 }}>
          Our opening hours are Monday to Friday 9am-5pm.
          </div>
          <div className="buttons">
          <Button variant="contained" color="primary" onClick={ () => setHelpRequired(false) }>Okay</Button>
          <Button variant="outlined" color="primary" onClick={callPhone}>Call us</Button>
          </div>
        </div>
      </div>
    </Modal>
  </React.Fragment>
}

export default ExportingComponent;
