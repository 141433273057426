import React from 'react';
import PageContainer from '../../components/Container';

const ExportingComponent = (props) => {
  return <PageContainer pageTitle="Maintenance" className="page-appointment-booked">
    <div className="page-header">
      <div className="left-side">
        <div className="title">Maintenance</div>
        <div className="description">We're currently working on solving some issues. Please try again later.</div>
      </div>
    </div>
    <div className="page-content">
      <p>If you have any queries please contact us on <a href={`tel:${process.env.REACT_APP_CC_PHONE_NUMBER}`}>{process.env.REACT_APP_CC_PHONE_NUMBER}</a></p>
    </div>
  </PageContainer>
}

export default ExportingComponent;
