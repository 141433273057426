import React, { useState, createContext, useContext, useEffect } from 'react';
import { Modal, Button } from '@mui/material';

const Context = createContext();

const ExportingComponent = (props) => {
  const [job, setJob] = useState({ voucher: null });
  const [configuration, setConfiguration] = useState(null);
  const [alert, setAlert] = useState({ 
    active: false, 
    title: '',
    message: ` `, 
    contact: true, 
    buttonLabel: null, 
    buttonOnClick: null 
  });

  useEffect(() => {
    localStorage.removeItem("@currentJob");
  }, []);

  const dismissAlert = () => {
    setAlert({ active: false, title: '', message: '', contact: true, buttonLabel: null, buttonOnClick: null })
  }

  return (<Context.Provider value={{
    job,
    setJob,
    setAlert,
    alert,
    configuration,
    setConfiguration
  }}>
    <Modal open={alert.active} onClose={dismissAlert}>
      <div className="alert-dialog-container">
        <div className="alert-content animate__animated animate__bounceIn">
          <div className="title">{alert.title}</div>
          <div className="message">{alert.message}</div>
          <div className="buttons">
          {
              alert.buttonLabel && <Button variant={alert.buttonVariant || 'outlined'} color="primary" onClick={() => {
                dismissAlert();
                alert.buttonOnClick();
              }}>
                { alert.buttonLabel }
              </Button>
            }
            <Button variant="contained" color="primary" onClick={dismissAlert}>{ alert.okButtonLabel || 'Okay' } </Button>
            {
              alert.contact && <Button variant="outlined" color="primary" onClick={() => {
                dismissAlert();
                window.dispatchEvent(new CustomEvent("onRequestHelp"))
              }}>Contact us</Button>
            }
          </div>
        </div>
      </div>
    </Modal>

    {props.children}
  </Context.Provider>)
}

export const GlobalContext = () => useContext(Context);

export default ExportingComponent;