import Axios from 'axios';
import axiosRetry from 'axios-retry';
import { useState, useLayoutEffect } from 'react';
import amplitude from 'amplitude-js'
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import Logger from "@rsconnectuk/pkg-logger";
import packageJSON from '../../package.json';
const { Postcode } = require("@rsconnectuk/lib-rsconnect-postcode")

var contentful = require('contentful');

let retriesCount = 0;

axiosRetry(Axios, {
  retryDelay: () => 1750,
  retryCondition: function (err) {
    retriesCount++;
    if (err.response && err.response.status === 500 && retriesCount < 5) {
      return true;
    } else {
      retriesCount = 0;
      return false;
    }
  }
});

export function useWindowSize () {
  let [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize () {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const getCurrentJob = async () => {
  const jobData = await localStorage.getItem(`@currentJob`);
  if (jobData) return JSON.parse(jobData);
  else return null;
}

export const logError = async (codeName, errorObject, extraJSON = {}) => {
  try {
    const appVersion = packageJSON.version;
    const job = await getCurrentJob();
    const loggerName = process.env.REACT_APP_API_ENVIRONMENT === "live"
      ? "BOOKING_BOT"
      : "BOOKING_BOT_DEV";
    const logz = new Logger(loggerName);
    const logBody = {
      code: codeName,
      error: errorObject,
      payload: JSON.stringify(extraJSON),
      extraProperties: {
        voucher: job ? job.voucher : "Guest",
        appVersion
      }
    };

    await logz.error(logBody);

    if (window.location.hostname === "localhost") {
      console.error(logBody);
    }
  } catch (err) {
    console.error("failed to create log", err);
  }

};

export const createAmplitudeEvent = async (eventType, obj) => {
  try {
    const job = await getCurrentJob();
    if (!job) {
      await logError(`CREATING_AMP_EVENT_WITHOUT_VOUCHER`, {
        eventType, obj
      });
      return false;
    }
    const project = amplitude.getInstance();
    const key = process.env.REACT_APP_AMPLITUDE_KEY || "Not found Amplitude Key";
    project.init(key)
    amplitude.setUserId(job.voucher);
    amplitude.getInstance().setUserProperties({
      "Company": job.contract_name,
      "Booking Type": job.contract_type,
      "Configuration": job.configuration_name
    });
    project.logEvent(eventType, { ...obj });
  } catch (err) {
    console.error(err);

    await logError("SEND_TO_AMPLITUDE", err);
  }
}


export function injectElement (fileName, type, rel) {

  var head = document.head;
  var link = document.createElement("link");

  if (type) {
    link.type = type;
  }

  link.rel = rel;
  link.href = fileName;

  head.appendChild(link);
}

export function injectMeta (name, content) {

  var head = document.head;
  var meta = document.createElement("meta");

  meta.name = name;
  meta.content = content;

  head.appendChild(meta);
}

let contentful_client = null;

const createContentfulClient = async () => {
  contentful_client = await contentful.createClient({
    space: `jws129mawoh1`,
    accessToken: 'i43hNQ5J3QsRBrCP82nQ7a0B-41iaK0iaSbAU2c-kHI'
  })
}

export const getContentfulByEntryID = async (entry_id) => {
  if (contentful_client === null) await createContentfulClient();
  const createPromise = () => new Promise((resolve, reject) => {
    contentful_client.getEntry(entry_id).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    })
  })
  return await createPromise();

}

export const readContentContentful = (content) => {

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <p style={{ margin: 0 }}>
          <img src={`https://` + node.data.target.fields.file.url} alt="Contentful" />
        </p>
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return <a target="_blank" rel="noopener noreferrer" className="color-link" href={`https://` + node.data.target.fields.file.url}>{children}</a>
      },
      [INLINES.HYPERLINK]: (node, _) => {
        const isLink = (node.data.uri.match('http') || node.data.uri.match('https')) ? true : false;
        return !isLink ? <a className="color-link" href={node.data.uri}>{node.content[0].value}</a> :
          <a target="_blank" rel="noopener noreferrer" className="color-link" href={node.data.uri}>{node.content[0].value}</a>
      }
    }
  };

  return documentToReactComponents(content, options)
}

export const existsInPublic = async (web_path) => {
  const createPromise = () => new Promise((resolve, reject) => {
    Axios.get(`${window.location.origin}${web_path}`).then((res) => {
      resolve(res);
    }).catch((err) => reject(err))
  })
  return await createPromise();
}

export const searchForPostcode = async (postcode) => {
  try {
    const poc = new Postcode(process.env.REACT_APP_API_ENVIRONMENT === "live" ? "live" : "dev");
    return (await poc.getPostCodeDetail({ postcode }));
  } catch (err) {
    console.log("error", err)
    throw err;
  }
}

const notDataEditablesStatuses = [
  "No Show",
  "Complete",
  "Complete (Prov)",
  "Engineer Leave/Sick",
  "Complete Not Active",
  "Cancelled",
  "Cancelled (API)",
  "Cancelled Policy",
  "Cancelled by Engineer",
  "Cancelled Appointment"
];

export const isStatusEditable = (jobStatus) => {
  return notDataEditablesStatuses.includes(jobStatus) ? false : true;
}

export const isJobBooked = (job) => job.jobStatus === "Confirmed Appointment";
